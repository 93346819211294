<template>
    <div class="w-full">
		<SiteHeader />
        <section class="w-full py-16">
            <div class="container mx-auto">
                <div class="w-full mx-auto xl:w-11/12">
                    <div class="mb-6"><a name="DrChinPakLin"></a>
                        <h3 class="text-xl font-normal leading-none text-center font-opensans lg:text-2xl text-siteBg-200">About</h3>
                        <h2 class="mb-2 text-4xl leading-none text-center lg:text-5xl 2xl:text-6xl font-playfair text-siteBg-100">Dr Chin Pak Lin</h2>
                        <h6 class="text-lg font-semibold text-center font-opensans">Hip and Knee Specialist</h6>
                        <h6 class="text-lg font-normal text-center font-opensans">MBBS, MRCSEd, MMed (Ortho), FRCSEd (Ortho)</h6>
                    </div>
                    <p class="text-left lg:text-justify">Dr Chin, a <span class="font-semibold">hip and knee specialist surgeon</span>, brings with him <span class="font-semibold">over 20 years of experience</span> in helping patients overcome pain and injuries. He co-founded The Orthopaedic Centre to provide quality care and modern treatment methods for all patients.</p>
                    <div class="flex flex-wrap items-center w-full mt-6 lg:my-6">
                        <div class="w-full lg:pr-6 lg:w-1/2">
                            <img src="/images/drchinpaklin.jpg" class="w-full mb-6 rounded-md lg:mb-0">
                        </div>
                        <div class="w-full lg:pl-6 lg:w-1/2">
                            <ul class="pl-4 mb-6 leading-relaxed list-disc">
                                <li class="pb-2 pl-2">Graduated from the <span class="font-semibold">National University of Singapore (NUS)</span> in 1998.</li>
                                <li class="pb-2 pl-2">Subsequently obtained his <span class="font-semibold">Fellowship of the Royal College of Surgeons of Edinburgh in Orthopaedic Surgery.</span></li>
                                <li class="pb-2 pl-2">Completed his advance joint replacement training at the <span class="font-semibold">Manchester Royal Infirmary</span> in the United Kingdom.</li>
                                <li class="pl-2">Former consultant at the adult reconstructive service in the Department of Orthopaedic Surgery at the <span class="font-semibold">Singapore General Hospital (SGH).</span></li>
                            </ul>
                            <p>Dr Chin has honed his skills in these areas of interest:</p>
                            <ul class="pl-4 mb-6 leading-relaxed list-disc">
                                <li class="pb-2 pl-2">Complex joint replacement surgery</li>
                                <li class="pb-2 pl-2">Hip and knee surgeries</li>
                                <li class="pb-2 pl-2">Treatment from sports injuries to cartilage wear leading to arthritis</li>
                                <li class="pb-2 pl-2">Received joint-specific training for the hip and knee</li>
                                <li class="pb-2 pl-2">Robotic knee surgery</li>
                                <li class="pl-2">Meniscus & ligament injuries</li>
                            </ul>
                            <p>Over the years, Dr Chin introduced several key developments to the field of orthopaedics:</p>
                            <ul class="pl-4 mb-6 leading-relaxed list-disc">
                                <li class="pb-2 pl-2">Computer-aided orthopaedic surgery</li>
                                <li class="pb-2 pl-2">Robotic knee surgery</li>
                                <li class="pl-2">Revision hip and knee surgery (surgical rescue)</li>
                            </ul>
                        </div>
                    </div>
                    <p>Aside from clinical work, Dr Chin is an active volunteer and educator. He served as an Adjunct Assistant Professor at the Duke-NUS Medical School and is currently a senior clinical tutor at the Yong Loo Lin, School of Medicine, NUS. He also volunteers his services at the Ang Mo Kio Community Hospital and in the Singapore International Foundation (SIF) specialist project in Cambodia.</p>
                    <div class="flex pb-6 mt-12">
                        <a href="#contactus" class="px-8 py-2 mx-auto text-lg text-center text-white rounded-full lg:px-10 lg:float-left bg-siteBg-300 hover:underline">Consult Dr Chin Today</a>
                    </div>
                </div>
            </div>
        </section>
        <section class="w-full pb-16 bg-siteBg-400">
            <div class="relative w-full pt-16 section-bg">
                <div class="container mx-auto">
                    <div class="w-full mx-auto xl:w-11/12 pb-36">
                        <h3 class="mb-2 text-4xl leading-none text-center text-white lg:text-5xl 2xl:text-6xl font-playfair">Our Expertise</h3>
                    </div>
                </div>
            </div>
            <div class="relative z-10 pt-12 -mt-36 lg:-mt-36">
                <div class="container mx-auto">
                    <div class="w-full mx-auto xl:w-11/12">
                        <div class="grid grid-cols-1 gap-10 lg:grid-cols-3">
                            <div>
                                <img src="/images/orthopaedic-centre-img-01.jpg" class="w-full mb-8 rounded-lg" />
                                <h3 class="mb-6 text-xl leading-snug xl:text-2xl text-siteBg-200">Hip & Knee<span class="block">Surgeon</span></h3>
                                <p>The hip and knee joints are some of the most commonly treated joints in the human body. As such, Dr Chin seeks to provide long-term pain relief by tackling the root cause.</p>
                            </div>
                            <div>
                                <img src="/images/orthopaedic-centre-img-02.jpg" class="w-full mb-8 rounded-lg" />
                                <h3 class="mb-6 text-xl leading-snug xl:text-2xl text-siteBg-200">Computer Aided<span class="block">Robotic Surgery</span></h3>
                                <p>Dr Chin is a firm believer of using technology to provide better patient care. Using computer-aided robotic surgery allows for better treatment outcomes.</p>
                            </div>
                            <div>
                                <img src="/images/orthopaedic-centre-img-03.jpg" class="w-full mb-8 rounded-lg" />
                                <h3 class="mb-6 text-xl leading-snug xl:text-2xl text-siteBg-200">Minimally Invasive<span class="block">Techniques</span></h3>
                                <p>With minimally invasive techniques, Dr Chin helps patients recover quickly and with lower risks. Smaller incisions heal quicker and are less likely to get infected.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="w-full pb-16 bg-siteBg-400">
            <div class="relative w-full py-56 section-bg-1"></div>
            <div class="relative z-10 -mt-36">
                <div class="container mx-auto">
                    <div class="w-full mx-auto xl:w-11/12">
                        <div class="p-6 bg-white rounded-lg lg:p-12"><a name="RoboticAssistedSurgery"></a>
                            <h2 class="mb-8 text-4xl leading-none text-center lg:text-5xl 2xl:text-6xl font-playfair text-siteBg-100">Robotic-assisted Surgery</h2>
                            <p class="center">Robotic-assisted Surgery uses modern technology to perform successful procedures. In the hands of an experienced surgeon, robotic surgery is a safe, effective way to obtain long-term pain relief and return to a pain-free lifestyle.</p>
                            <p class="center">Apart from a higher degree of accuracy and increased precision, some other benefits of minimally invasive robotic knee surgery include:</p>
                            <div class="grid grid-cols-2 gap-10 my-12 lg:grid-cols-4">
                                <div>
                                    <img src="/images/icon-01.png" class="w-1/2 mx-auto mb-4 lg:w-1/3" />
                                    <h6 class="text-base leading-normal text-center text-siteBg-default ">Quicker Recovery<span class="block">& Minimal Scarring</span></h6>
                                </div>
                                <div>
                                    <img src="/images/icon-02.png" class="w-1/2 mx-auto mb-4 lg:w-1/3" />
                                    <h6 class="text-base leading-normal text-center text-siteBg-default ">More Natural Feeling<span class="block">After Recovery</span></h6>
                                </div>
                                <div>
                                    <img src="/images/icon-03.png" class="w-1/2 mx-auto mb-4 lg:w-1/3" />
                                    <h6 class="text-base leading-normal text-center text-siteBg-default ">Reduced Risks<span class="block">& Infections</span></h6>
                                </div>
                                <div>
                                    <img src="/images/icon-04.png" class="w-1/2 mx-auto mb-4 lg:w-1/3" />
                                    <h6 class="text-base leading-normal text-center text-siteBg-default ">Higher Patient<span class="block">Satisfaction</span></h6>
                                </div>
                            </div>
                            <div class="flex mt-12">
                                <a href="#contactus" class="px-8 py-2 mx-auto text-lg text-center text-white rounded-full lg:px-10 lg:float-left bg-siteBg-300 hover:underline">Consult Dr Chin Today</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container mx-auto">
                <div class="w-full py-12 mx-auto xl:w-11/12"><a name="OurServices"></a>
                    <h2 class="mb-8 text-4xl leading-none text-center lg:text-5xl 2xl:text-6xl font-playfair text-siteBg-100">Our Services</h2>
                    <p class="center">At The Orthopaedic Centre, we believe that every patient deserves quality and reliable healthcare. We provide comprehensive general and sub-specialised orthopaedic services, trauma and sports services.</p>
                    <p class="center">Some of the services we provide include:</p>
                </div>
            </div>
            <div class="container mx-auto">
                <div class="w-full mx-auto xl:w-11/12">
                    <div class="p-12 rounded-lg lg:p-12 bg-siteBg-500">
                        <div class="grid grid-cols-1 lg:grid-cols-3 lg:gap-10">
                            <div>
                                <ul class="list">
                                    <li class="pb-4 pl-2">Meniscus Surgery</li>
                                    <li class="pb-4 pl-2 lg:pb-0">Ligament Reconstructions</li>
                                </ul>
                            </div>
                            <div>
                                <ul class="list">
                                    <li class="pb-4 pl-2">Knee Joint Replacements</li>
                                    <li class="pb-4 pl-2 lg:pb-0">Hip Joint Replacements</li>
                                </ul>
                            </div>
                            <div>
                                <ul class="list">
                                    <li class="pb-4 pl-2">Complex Joint Replacements and Revision Surgeries</li>
                                    <li class="pl-2">Knee Injections</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="flex pb-6 mt-12">
                        <a href="#contactus" class="px-8 py-2 mx-auto text-lg text-center text-white rounded-full lg:px-10 lg:float-left bg-siteBg-300 hover:underline">Consult Dr Chin Today</a>
                    </div>
                </div>
            </div>
        </section>
        <section class="w-full py-16">
            <div class="container mx-auto">
                <div class="w-full mx-auto xl:w-11/12">
                    <h2 class="mb-8 text-4xl leading-none text-center lg:text-5xl 2xl:text-6xl font-playfair text-siteBg-100">Insurance</h2>
                    <p>Our clinic is on the specialist panels of the following Health Networks/ Insurance Plans, and we are happy to assist with your claims or attend to any query you may have.</p>
                    <!-- <p class="mx-auto center lg:w-9/12">We are on the specialists panels of the following Health Networks/Insurance Plans</p> -->
                    <p class="text-lg text-siteBg-default lg:text-2xl center">Personal Insurance:</p>
                    <div class="grid grid-cols-2 gap-8 lg:grid-cols-5">
                        <img src="/images/insurance-01.jpg" class="w-full" />
                        <img src="/images/insurance-02.jpg" class="w-full" />
                        <img src="/images/insurance-03.jpg" class="w-full" />
                        <img src="/images/insurance-04.jpg" class="w-full" />
                        <img src="/images/insurance-05.jpg" class="w-full" />
                    </div>
                    <p class="mt-10 text-lg text-siteBg-default lg:text-2xl center">Corporate Insurance:</p>
                    <div class="w-full lg:mx-auto lg:w-10/12">
                        <div class="grid grid-cols-2 gap-8 lg:grid-cols-4">
                            <img src="/images/insurance-06.jpg" class="w-full" />
                            <img src="/images/insurance-07.jpg" class="w-full" />
                            <img src="/images/insurance-08.jpg" class="w-full" />
                            <img src="/images/insurance-09.jpg" class="w-full" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="w-full py-16 section-bg">
            <div class="container mx-auto">
                <div class="w-full mx-auto xl:w-11/12">
                    <div class="w-full mx-auto lg:w-10/12">
                        <h3 class="text-2xl leading-snug text-center text-white lg:text-3xl 2xl:text-4xl font-playfair">The Orthopaedic Centre is dedicated to provide personalise, practical and holistic care for all patients.</h3>
                        <div class="grid grid-cols-2 gap-10 mt-12 lg:grid-cols-4">
                            <div>
                                <img src="/images/icon-05.png" class="w-1/2 mx-auto mb-4 lg:w-1/3" />
                                <h6 class="text-lg font-semibold leading-relaxed text-center text-white">Same-Day<span class="block">Appointments</span></h6>
                            </div>
                            <div>
                                <img src="/images/icon-06.png" class="w-1/2 mx-auto mb-4 lg:w-1/3" />
                                <h6 class="text-lg font-semibold leading-normal text-center text-white">One-Stop Clinic<span class="block">With Subspecialties</span></h6>
                            </div>
                            <div>
                                <img src="/images/icon-07.png" class="w-1/2 mx-auto mb-4 lg:w-1/3" />
                                <h6 class="text-lg font-semibold leading-normal text-center text-white">Insurance & Medisave<span class="block">Claimable</span></h6>
                            </div>
                            <div>
                                <img src="/images/icon-08.png" class="w-1/2 mx-auto mb-4 lg:w-1/3" />
                                <h6 class="text-lg font-semibold leading-normal text-center text-white">Affordable &<span class="block">Transparent Pricing</span></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="flex flex-wrap border-b border-gray-200 border-solid">
			<div class="w-full bg-top bg-no-repeat bg-cover lg:w-1/2" style="background-image: url('/images/drchinpaklin-pic.jpg')">
				<img src="/images/drchinpaklin-pic.jpg" class="block w-full lg:hidden" />
			</div>
			<div class="flex justify-start w-full lg:w-1/2 bg-siteRed-100"><a name="contactus"></a>
				<div class="py-12 container-half lg:pl-16 lg:py-24">
					<p class="text-siteBg-default">Enquire on your condition or query today.<br />Please call us at <a href="tel:+6563395063" class="font-semibold">6339 5063</a> or use the form below to reach us.</p>
                    <SiteForm />
				</div>
			</div>
    	</section>
        <section class="w-full py-16">
            <div class="container mx-auto">
                <div class="w-full mx-auto xl:w-11/12"><a name="FAQ"></a>
                    <h2 class="mb-8 text-4xl leading-none text-center lg:text-5xl 2xl:text-6xl font-playfair text-siteBg-100">Frequently Asked Questions (FAQs)</h2>
                    <div>
                        <div class="mb-2 border-b border-solid border-siteBg-100">
                            <div @click="index = 0" class="flex items-center justify-between py-4 rounded-lg cursor-pointer bg-siteRed-100">
                                <h6 class="text-lg text-siteBg-default lg:text-2xl">When Do I Need To See An Orthopaedic Specialist?</h6>
                                <div class="text-2xl transition duration-500 delay-200 transform " :class="index === 0 ? 'rotate-45' : ''">
                                    <svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-siteBg-100 plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <collapse-transition>
                            <div v-show="index === 0" class="pt-4 pb-8">
                                <p class="mb-6 text-siteBg-default">If you experience long-term pain, we strongly advise that you see a specialist to obtain treatment quickly. Some of the conditions and procedures that Dr Chin sees are listed below.</p>
                                <h3 class="mb-2 text-lg font-semibold font-opensans">Knee:</h3>
                                <ul class="list-disc list-inside text-siteBg-default">
                                    <li class="mb-2">Meniscus Tear</li>
                                    <li class="mb-2">Knee Fractures</li>
                                    <li class="mb-2">Ligament Tears</li>
                                    <li class="mb-2">Cartilage Injuries</li>
                                    <li class="mb-2">Knee Arthroscopy</li>
                                    <li class="mb-6">Knee Replacement</li>
                                </ul>
                                <h3 class="mb-2 text-lg font-semibold font-opensans">Hip:</h3>
                                <ul class="list-disc list-inside text-siteBg-default">
                                    <li class="mb-2">Hip Replacement</li>
                                    <li class="mb-2">Hip Fractures</li>
                                    <li class="mb-2">Hip Arthroplasty</li>
                                    <li class="mb-2">Arthritis</li>
                                    <li class="mb-6">Joint Revision Surgery</li>
                                </ul>
                                <p class="text-siteBg-default">To find out more about our services, call us at <a href="tel:+6563395063" class="font-semibold">63395063</a> to enquire now.</p>
                            </div>
                            </collapse-transition>
                        </div>
                        <div class="mb-2 border-b border-solid border-siteBg-100">
                            <div @click="index = 1" class="flex items-center justify-between py-4 rounded-lg cursor-pointer bg-siteRed-100">
                                <h6 class="text-lg text-siteBg-default lg:text-2xl">Do I Need A Referral To See Dr Chin?</h6>
                                <div class="text-2xl transition duration-500 delay-200 transform " :class="index === 1 ? 'rotate-45' : ''">
                                    <svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-siteBg-100 plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <collapse-transition>
                            <div v-show="index === 1" class="pt-4 pb-8">
                                <p class="mb-4 text-siteBg-default">While the clinic does not require a referral letter to accept same-day appointments or walk-ins, a referral letter from the GP to see our specialist may be required for insurance claiming purposes.</p>
                            </div>
                            </collapse-transition>
                        </div>
                        <div class="mb-2 border-b border-solid border-siteBg-100">
                            <div @click="index = 2" class="flex items-center justify-between py-4 rounded-lg cursor-pointer bg-siteRed-100">
                                <h6 class="text-lg text-siteBg-default lg:text-2xl">Why Is Dr Chin Proficient In Robotic Surgery?</h6>
                                <div class="text-2xl transition duration-500 delay-200 transform " :class="index === 2 ? 'rotate-45' : ''">
                                    <svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-siteBg-100 plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <collapse-transition>
                            <div v-show="index === 2" class="pt-4 pb-8">
                                <p class="text-siteBg-default">Dr Chin’s medical philosophy is to provide a positive patient experience. As such, he is always on the lookout for newer and safer treatment methods for his patients.</p>
                                <p class="mb-4 text-siteBg-default">Robotic surgery is one such treatment method, as he can augment his surgical skills with the benefits of computer-aided precision. With modern advances in both surgical and non-surgical treatments, patients benefit from more treatment options with quicker recovery times and reduced discomfort.</p>
                            </div>
                            </collapse-transition>
                        </div>
                        <div class="mb-2 border-b border-solid border-siteBg-100">
                            <div @click="index = 3" class="flex items-center justify-between py-4 rounded-lg cursor-pointer bg-siteRed-100">
                                <h6 class="text-lg text-siteBg-default lg:text-2xl">How Do Robotic Surgery And Computer-Aided Techniques Work?</h6>
                                <div class="text-2xl transition duration-500 delay-200 transform " :class="index === 3 ? 'rotate-45' : ''">
                                    <svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-siteBg-100 plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
                                </div>
                            </div>
                            <collapse-transition>
                            <div v-show="index === 3" class="pt-4 pb-8">
                                <p class="text-siteBg-default">Similar to a GPS system, Robotic surgery requires a “driver” in the form of a surgeon.  As such, the benefits of such methods are still largely dependent on the surgeon’s surgical skills.</p>
                                <p class="mb-4 text-siteBg-default">With Robotic or Computer-Aided surgical techniques, the patients can lower their risks of complications and a quicker recovery rate.</p>
                            </div>
                            </collapse-transition>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <SiteFooter />
    </div>
</template>

<script>
import {CollapseTransition} from "vue2-transitions";
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import SiteForm from '@/components/SiteForm.vue'

export default {
	name: 'Home',
	components: {
		SiteHeader,
        SiteFooter,
        SiteForm,
        CollapseTransition
	},
	data(){
		return {
			index: 0,
		}
    },
}
</script>
<style>
.section-bg{
    background-image: url('/images/bg-img-01.jpg');
    @apply bg-top;
    @apply bg-no-repeat;
    @apply bg-cover;
    @apply bg-siteBg-100;
}
.section-bg-1{
    background-image: url('/images/bg-img-02.jpg');
    @apply bg-top;
    @apply bg-no-repeat;
    @apply bg-cover;
}
ul.list{
    @apply text-white;
    @apply list-outside;
    @apply list-disc;
}
</style>