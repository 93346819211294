<template>
    <div class="w-full">
        <div class="py-2 bg-white">
            <div class="container mx-auto">
                <div class="flex flex-row items-center justify-between w-full mx-auto xl:w-11/12 2xl:w-11/12">
                    <div class="w-1/2">
                        <img src="/images/logo.svg" class="h-20 lg:h-24" />
                    </div>
                    <div class="w-1/2">
                        <div class="flex items-end justify-end text-right">
                            <span class="text-base font-normal font-opensans lg:text-xl text-siteBg-200">Call us at</span>
                        </div>
                        <div class="flex flex-row items-end justify-end">
                            <img src="/images/call-icon.png" class="h-6 mr-2 lg:h-8" />
                            <a href="tel:+6563395063" class="text-lg font-light text-siteBg-200 lg:text-2xl font-opensans">(+65) <span class="font-bold">6339 5063</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-16 bg-siteBg-400">
            <div class="container mx-auto">
                <div class="w-full mx-auto xl:w-11/12">
                    <h2 class="mb-12 text-4xl leading-none text-center lg:text-5xl 2xl:text-6xl font-playfair text-siteBg-100">Confirmation Message</h2>
                    <p class="center">Thank you for your kind interest in Dr Chin Pak Lin | The Orthopaedic Centre.</p>
                    <p class="mb-6 center">We will attend to your request as soon as possible.</p>
                    <div class="flex mt-12">
                        <a href="/" class="px-8 py-2 mx-auto text-lg text-center text-white rounded-full lg:px-10 lg:float-left bg-siteBg-300 hover:underline">Back To HomePage</a>
                    </div>
                </div>
            </div>
        </div>
        <SiteFooter />
    </div>
</template>

<script>
import SiteFooter from '@/components/SiteFooter.vue'

export default {
	name: 'Thank You',
	components: {
        SiteFooter,
	},
	data(){
    },
}
</script>