<template>
  <div class="mt-10">
    <form @submit.prevent="submit" class="space-y-3 lg:space-y-4">
        <div class="grid grid-cols-1 gap-6 lg:grid-cols-1">
          <div class="">
            <div class="relative border-2 border-gray-400 rounded-lg contact-form hover:border-gray-600" :class="{'border-red-500':errors.name}">
              <input id='name' type="text" class="block w-full px-3 py-2 rounded-lg appearance-none focus:outline-none" v-model="fields.name"
                placeholder=" " :class="{'text-red-500 placeholder-red-500 border-red-500': errors.name}">
              <label for="name" class="absolute top-0 px-3 py-2 duration-300" :class="{'text-red-500':errors.name}">Name</label>
            </div>
            <InputError class="text-sm" :field="errors.name"/>
          </div>
          <div class="">
            <div class="relative border-2 border-gray-400 rounded-lg contact-form hover:border-gray-600" :class="{'border-red-500':errors.contact}">
              <input id='contact' type="tel" class="block w-full px-3 py-2 rounded-lg appearance-none focus:outline-none"
                v-model="fields.contact" placeholder=" "
                :class="{'text-red-500 placeholder-red-500 border-red-500': errors.contact}">
              <label for="contact" class="absolute top-0 px-3 py-2 duration-300" :class="{'text-red-500':errors.contact}">Contact Number</label>
            </div>
            <InputError class="text-sm" :field="errors.contact"/>
          </div>
          <div class="">
            <div class="relative border-2 border-gray-400 rounded-lg contact-form hover:border-gray-600" :class="{'border-red-500':errors.email}">
              <input id='email' type="email" class="block w-full px-3 py-2 rounded-lg appearance-none focus:outline-none"
                v-model="fields.email"
                placeholder=" " :class="{'text-red-500 placeholder-red-500 border-red-500': errors.email}">
              <label for="email" class="absolute top-0 px-3 py-2 duration-300" :class="{'text-red-500':errors.email}">Email</label>
            </div>
            <InputError class="text-sm" :field="errors.email"/>
          </div>
        </div>
        <div class="pt-2">
          <div class="relative border-2 border-gray-400 rounded-lg contact-form hover:border-gray-600"  :class="{'border-red-500':errors.message}">
          <textarea id='message' class="block w-full h-32 px-5 py-4 rounded-lg appearance-none focus:outline-none"
              v-model="fields.message" placeholder=" "
              :class="{'text-red-500 placeholder-red-500 border-red-500': errors.message}"></textarea>
            <label for="message" class="absolute top-0 px-3 py-2 duration-300" :class="{'text-red-500':errors.message}">Message</label>
          </div>
          <InputError class="text-sm" :field="errors.message"/>
        </div>
        <div class="">
            <button type="submit" class="px-8 py-2 mx-auto text-lg text-center text-white rounded-full lg:px-10 lg:float-left bg-siteBg-300 hover:underline">
            <LoaderTailSpin v-if="busy" class="absolute left-0 inline-block w-5 h-5 mt-1 ml-2" color="red"/>
            <span class="">Consult Dr Chin Today</span>
            </button>
        </div>
    </form>
  </div>
</template>

<script>
import hapiMixins from "@juno0424/vue2-hapi";
import { InputError } from "@juno0424/vue2-hapi-inputs";

export default {
  mixins: [hapiMixins],
  components: { InputError },
  data() {
    return {
      endpoint: "https://hapiform.sg/api/d66b86bb-ad27-4663-ba18-56eb3090b213",
      redirectTo: "/thank-you"
    };
  },
};
</script>

<style scoped>
.contact-form input:hover ~ label,
.contact-form input:focus-within ~ label,
.contact-form input:not(:placeholder-shown) ~ label {
    @apply z-0 px-2 py-0 ml-3 text-black origin-left transform scale-75 -translate-y-4 bg-white;
}
.contact-form textarea:hover ~ label,
.contact-form textarea:focus-within ~ label,
.contact-form textarea:not(:placeholder-shown) ~ label {
    @apply z-0 px-2 py-0 ml-3 text-black origin-left transform scale-75 -translate-y-4 bg-white;
}
</style>